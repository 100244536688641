
















import Header from '@/components/Header.vue';

import { Component, Vue } from "vue-property-decorator";

// Components
// import Navigation from "@/components/Navigation.vue"; // @ is an alias to /src
// import Footer from "@/components/Footer.vue"; // @ is an alias to /src
@Component({
  components: {
    Header
  }
})
export default class MessagesPage extends Vue {
  

}


